@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');

:root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
    :root {
        --foreground-rgb: 255, 255, 255;
        --background-start-rgb: 0, 0, 0;
        --background-end-rgb: 0, 0, 0;
    }
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Inter', sans-serif;
    --font-sans-serif: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    --font-serif: Georgia, Times, serif;
    --font-mono: Menlo, Courier, monospace;
}

.mark-down-text {
    word-break: break-word;
    white-space: break-spaces;
    color: #484848;
    font: inherit;
    font-size: inherit;
    line-height: inherit;
}

.mark-down-text p {
    white-space: pre-wrap;
    word-break: break-word;
    color: #484848;
    font: inherit;
    font-size: inherit;
    line-height: inherit;
}

.mark-down-text p a {
    color: #3b82f6 !important;
}

.mark-down-text p:last-child {
    margin: 0;
    padding: 0;
}

strong,
b {
    font-weight: 600 !important;
}

.input[type='radio']:checked {
    content: '';
    display: block;
    position: relative;
    top: 3px;
    left: 3px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: red;
}

.side {
    background-color: rgba(0, 191, 255, 0.04);
}

.attachment {
    height: 250px;
}

.bannerdiv:hover .editbannerdiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.screen-navbar {
    max-height: calc(100vh - 68px);
    overflow: auto;
}

.geIUsg div:first-child {
    white-space: nowrap;
    overflow: visible !important;
    text-overflow: ellipsis;
}

.react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: 0.01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 0;
    background: #fff;
    border: 1px solid #cacaca;
    border-radius: 4px;
    line-height: 25px;
    height: 46px !important;
    width: 100% !important;
    outline: none;
    padding: 12px 16px 12px 64px !important;
}

.react-tel-input .selected-flag {
    outline: none;
    position: relative;
    width: 52px !important;
    height: 100% !important;
    padding: 0 0 0 14px;
    border-radius: 3px 0 0 3px;
}

.form-title {
    width: calc(100% - 56px);
}

.picker {
    z-index: 9999 !important;
}

#confetti-canvas {
    z-index: 999999 !important;
}

/* width */
div::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    margin-bottom: 20px;
}

/* Track */
div::-webkit-scrollbar-track {
    background: transparent !important;
}

/* Handle */
div::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 5px;
}

div:hover::-webkit-scrollbar-thumb {
    background: #bababa;
}

/* Handle on hover */
div::-webkit-scrollbar-thumb:hover {
    background: #858585;
}

.prompt-input-wrapper {
    &:hover {
        background-image: linear-gradient(135.6deg, #FE3678 0%, #0764EB 98.97%) !important;
    }

    &:focus-within {
        background-image: linear-gradient(135.6deg, #FE3678 0%, #0764EB 98.97%) !important;
    }
}

.rc-slider {
    position: relative;
    width: 100%;
    height: 14px;
    padding: 5px 0;
    border-radius: 6px;
    touch-action: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-rail {
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 6px;
    --tw-bg-opacity: 1;
    background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.rc-slider-track {
    position: absolute;
    height: 4px;
    border-radius: 6px;
    --tw-bg-opacity: 1;
    background-color: rgb(7 100 235 / var(--tw-bg-opacity));
}

.rc-slider-handle {
    position: absolute;
    width: 14px;
    height: 14px;
    margin-top: -5px;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.rc-slider-handle:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.rc-slider-handle {
    border-width: 2px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgb(17 24 39 / var(--tw-border-opacity));
}

.rc-slider-handle:is(.dark *) {
    --tw-border-opacity: 1;
    border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.rc-slider-handle {
    border-radius: 50%;
    cursor: pointer;
    cursor: grab;
    touch-action: pan-x;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    --tw-border-opacity: 1;
    border-color: rgb(17 24 39 / var(--tw-border-opacity));
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging:is(.dark *) {
    --tw-border-opacity: 1;
    border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    box-shadow: 0 0 0 5px #111827;
}

.rc-slider-handle:focus {
    outline: none;
    box-shadow: none;
}

.rc-slider-handle:focus-visible {
    --tw-border-opacity: 1;
    border-color: rgb(17 24 39 / var(--tw-border-opacity));
}

.rc-slider-handle:focus-visible:is(.dark *) {
    --tw-border-opacity: 1;
    border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.rc-slider-handle:focus-visible {
    box-shadow: 0 0 0 3px #111827;
}

.rc-slider-handle-click-focused:focus {
    --tw-border-opacity: 1;
    border-color: rgb(17 24 39 / var(--tw-border-opacity));
}

.rc-slider-handle-click-focused:focus:is(.dark *) {
    --tw-border-opacity: 1;
    border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.rc-slider-handle-click-focused:focus {
    box-shadow: unset;
}

.rc-slider-handle:hover {
    --tw-border-opacity: 1;
    border-color: rgb(17 24 39 / var(--tw-border-opacity));
}

.rc-slider-handle:hover:is(.dark *) {
    --tw-border-opacity: 1;
    border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.rc-slider-handle:active {
    --tw-border-opacity: 1;
    border-color: rgb(17 24 39 / var(--tw-border-opacity));
}

.rc-slider-handle:active:is(.dark *) {
    --tw-border-opacity: 1;
    border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.rc-slider-handle:active {
    box-shadow: 0 0 5px #111827;
    cursor: grabbing;
}

.rc-slider-mark {
    position: absolute;
    top: 18px;
    left: 0;
    width: 100%;
    font-size: 12px;
}

.rc-slider-mark-text {
    position: absolute;
    display: inline-block;
    color: #999;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
}

.rc-slider-mark-text-active {
    color: #666;
}

.rc-slider-step {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;
    pointer-events: none;
}

.rc-slider-dot {
    position: absolute;
    bottom: -2px;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.rc-slider-dot:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.rc-slider-dot {
    border: 2px solid #e9e9e9;
    border-radius: 50%;
    cursor: pointer;
}

.rc-slider-dot-active {
    --tw-border-opacity: 1;
    border-color: rgb(17 24 39 / var(--tw-border-opacity));
}

.rc-slider-dot-active:is(.dark *) {
    --tw-border-opacity: 1;
    border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.rc-slider-dot-reverse {
    margin-right: -4px;
}

.rc-slider-disabled {
    background-color: #e9e9e9;
}

.rc-slider-disabled .rc-slider-track {
    background-color: #ccc;
}

.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.rc-slider-disabled .rc-slider-handle:is(.dark *),
.rc-slider-disabled .rc-slider-dot:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
    border-color: #ccc;
    box-shadow: none;
    cursor: not-allowed;
}

.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
    cursor: not-allowed !important;
}

.rc-slider-vertical {
    width: 14px;
    height: 100%;
    padding: 0 5px;
}

.rc-slider-vertical .rc-slider-rail {
    width: 4px;
    height: 100%;
}

.rc-slider-vertical .rc-slider-track {
    bottom: 0;
    left: 5px;
    width: 4px;
}

.rc-slider-vertical .rc-slider-handle {
    margin-top: 0;
    margin-left: -5px;
    touch-action: pan-y;
}

.rc-slider-vertical .rc-slider-mark {
    top: 0;
    left: 18px;
    height: 100%;
}

.rc-slider-vertical .rc-slider-step {
    width: 4px;
    height: 100%;
}

.rc-slider-vertical .rc-slider-dot {
    margin-left: -2px;
}

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
    display: block !important;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-play-state: paused;
}

.rc-slider-tooltip-zoom-down-leave {
    display: block !important;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-play-state: paused;
}

.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
    animation-name: rcSliderTooltipZoomDownIn;
    animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
    animation-name: rcSliderTooltipZoomDownOut;
    animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
    transform: scale(0, 0);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.rc-slider-tooltip-zoom-down-leave {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

@keyframes rcSliderTooltipZoomDownIn {
    0% {
        transform: scale(0, 0);
        transform-origin: 50% 100%;
        opacity: 0;
    }
    100% {
        transform: scale(1, 1);
        transform-origin: 50% 100%;
    }
}

@keyframes rcSliderTooltipZoomDownOut {
    0% {
        transform: scale(1, 1);
        transform-origin: 50% 100%;
    }
    100% {
        transform: scale(0, 0);
        transform-origin: 50% 100%;
        opacity: 0;
    }
}

.rc-slider-tooltip {
    position: absolute;
    top: -9999px;
    left: -9999px;
    visibility: visible;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-tooltip * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-tooltip-hidden {
    display: none;
}

.rc-slider-tooltip-placement-top {
    padding: 4px 0 8px 0;
}

.rc-slider-tooltip-inner {
    min-width: 24px;
    height: 24px;
    padding: 6px 2px;
    color: #fff;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    background-color: #6c6c6c;
    border-radius: 6px;
    box-shadow: 0 0 4px #d9d9d9;
}

.rc-slider-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
    bottom: 4px;
    left: 50%;
    margin-left: -4px;
    border-width: 4px 4px 0;
    border-top-color: #6c6c6c;
}

/* Custom scrollbar design starts from here */
.os-theme-thin > .os-scrollbar-vertical {
    bottom: 14px;
    width: 14px;
    padding: 6px 0;
}

.os-theme-thin > .os-scrollbar-horizontal {
    right: 14px;
    height: 14px;
    padding: 0px 6px;
}

.os-theme-thin > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle {
    height: 100%;
    min-width: 30px;
}

.os-theme-thin.os-host-transition > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle:before {
    transition: height 0.3s, margin-top 0.3s, background 0.2s;
}

.os-theme-thin > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle:hover:before,
.os-theme-thin > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle.active:before {
    height: 4px;
    margin-top: -2px;
}

.os-theme-thin > .os-scrollbar-horizontal > .os-scrollbar-track:before,
.os-theme-thin > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle:before {
    left: 0;
    right: 0;
    height: 2px;
    top: 50%;
    margin-top: -1px;
}

.os-theme-thin > .os-scrollbar > .os-scrollbar-track {
    background: transparent;
}

.os-theme-thin > .os-scrollbar-horizontal > .os-scrollbar-track:before,
.os-theme-thin > .os-scrollbar-vertical > .os-scrollbar-track:before {
    content: '';
    display: block;
    position: absolute;
    background-color: rgb(46 46 46 / 0.1);
}

.os-theme-thin > .os-scrollbar-horizontal > .os-scrollbar-track:is(.dark *):before,
.os-theme-thin > .os-scrollbar-vertical > .os-scrollbar-track:is(.dark *):before {
    --tw-bg-opacity: 1;
    background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.os-theme-thin > .os-scrollbar-vertical > .os-scrollbar-track:before,
.os-theme-thin > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:before {
    top: 0;
    bottom: 0;
    width: 2px;
    left: 50%;
    margin-left: -1px;
}

.os-theme-thin > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle {
    width: 100%;
    min-height: 15px;
}

.os-theme-thin > .os-scrollbar-vertical > .os-scrollbar-track:before,
.os-theme-thin > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:before {
    top: 0;
    bottom: 0;
    width: 2px;
    left: 50%;
    margin-left: -1px;
    border-radius: 15px;
    background-color: rgb(46 46 46 / 0.1);
}

.os-theme-thin > .os-scrollbar-vertical > .os-scrollbar-track:is(.dark *):before,
.os-theme-thin > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:is(.dark *):before {
    --tw-bg-opacity: 1;
    background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.os-theme-thin > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle:before {
    content: '';
    display: block;
    position: absolute;
    border-radius: 10px;
    background-color: rgb(46 46 46 / 0.3);
}

.os-theme-thin > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle:is(.dark *):before {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.os-theme-thin > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:hover:before,
.os-theme-thin > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle.active:before {
    width: 2px;
    margin-left: -1px;
    background: #666;
}

.os-theme-thin.os-host-transition > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:before {
    transition: width 0.3s, margin-left 0.3s, background 0.2s;
}

/* ScrollBar Height Calc */
.cart-scrollbar {
    -webkit-overflow-scrolling: touch;
    max-height: calc(100vh - 160px);
}

/* .table-scrollbar {
  background-color: green;
} */
.table-scrollbar::-webkit-scrollbar {
    -webkit-appearance: none;
}

.table-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.075);
    border-radius: 10px;
    border: 2px solid red;
}

.css-3dah0e-MuiModal-root-MuiDialog-root {
    position: fixed;
    z-index: 10000 !important;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
}

.css-1nf2ro8 {
    position: fixed;
    z-index: 10000 !important;
    inset: 0px;
}

.MuiPopper-root {
    z-index: 10000 !important
}
.tiptap .is-editor-empty:first-child::before {
    color: attr(data-placeholder-color);
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
}

